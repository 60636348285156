import styles from './componentsNew/components.module.css'
import { Backdrop, Box, Container, Grid, CircularProgress, TextField, Autocomplete, Accordion, AccordionSummary, Typography, AccordionDetails } from "@mui/material"
import Header from "../../../components/header/Header"
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import TableCard from "../../../components/tableCard/TableCard";
import Create from "./componentsNew/Create";
import Table from './componentsNew/TableNew'
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchContratos, fetchTurnos, fetchWorkers, getAllContratos, getAllTurnos, getAllWorkers, getTurnosStatus, getUsersStatus, getWorkersStatus } from "../../../redux/features/users/userSlice";
import SkeletonTable from "../../../components/Skeleton";
import Filtro from './componentsNew/Filtro';
import Edit from './componentsNew/Edit';
import { fetchCargos, fetchReservas, getAllreservas, getCargosStatus, getReservasStatus, getAllCargos } from '../../../redux/features/reservas/reservaSlice';
import { useAuth } from '../../../context/AppContext';
import { fetchForecastAreas, fetchForecastGerencias, getAllAreas, getAllGerencia } from '../../../redux/features/forecast/forecastSlice';
import { createAxiosInstance } from '../../../api/axios';
import Header2 from '../../../components/header/Header2';
import Edit2 from './componentsNew/Edit2';

const data = {
  fechaIngreso:'',
  fechaSalida:'',
  descripcion:'',
  trabajadores:[]
}

const Reservas = () => {
  const { info } = useAuth();
  const empresas = info?.empresas;
  const dispatch = useDispatch();
  // const trabajadores = useSelector(getAllWorkers);
  // const contratos = useSelector(getAllContratos);
  const cargos = useSelector(getAllCargos);
  const cargosStatus = useSelector(getCargosStatus);
  const turnos = useSelector(getAllTurnos);
  const turnosStatus = useSelector(getTurnosStatus);
  const reservas = useSelector(getAllreservas)
  const status = useSelector(getReservasStatus);
  const [checkedState, setCheckedState] = useState([]); //ESCRIBIR PROPOSITO
  const gerencia = useSelector(getAllGerencia);
  const siArea = useSelector(getAllAreas);

  const [selectedTurnos, setSelectedTurnos] = useState([]);
  const [fechaInicio, setFechaInicio] = useState('');
  const [fechaTermino, setFechaTermino] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [jornada, setJornada] = useState('');
  const [turnoGeneral, setTurnoGeneral] = useState('');
  const [check, setCheck] = useState(0);
  const [open3, setOpen3] = useState(false);
  const [formData, setFormData] = useState(data);
  const [openEdit, setOpenEdit] = useState(false);
  const [seleItem, setSeleItem] = useState();
  const [open, setOpen] = useState(false);
  const [viceprecidencia, setViceprecidencia] = useState([]);

 
  useEffect(()=>{
    if(status == 'idle'){
      dispatch(fetchReservas());
    }
    if(cargosStatus == 'idle'){
      dispatch(fetchCargos());
    }
    if(turnosStatus == 'idle'){
      dispatch(fetchTurnos());
      dispatch(fetchForecastGerencias());
      dispatch(fetchForecastAreas());
    }
      // dispatch(fetchContratos());
  },[]);

  useEffect(() => {
    if (Array.isArray(gerencia)) {
      const vp = gerencia.filter(item => item.startsWith('VP'));
      setViceprecidencia(vp);
      console.log('vp', vp)
    }
  }, [gerencia]);

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open3}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        <Header 
          title="Gestión de reservas"
          subtitle="En este módulo, podrás registrar una nueva reserva en el sistema, además de visualizar un registro de las que ya fueron registradas."
          buttonProps={{ 
            disabled: false, 
            onClick: ()=> setOpen(true), 
            label: 'Crear'
          }}
          >
          {<HolidayVillageIcon style={{ fontSize:'2.0rem' }}/>}
        </Header>
        <TableCard>
          <div className={styles.create_filters}>
            <Grid container={true} spacing={1}>
              <Grid item  xs={12} sm={12} className={styles.center}>
                <Create formData={formData} data={data} setFormData={setFormData} checkedState={checkedState} setCheckedState={setCheckedState} setSelectedTurnos={setSelectedTurnos} selectedTurnos={selectedTurnos} fechaInicio={fechaInicio} fechaTermino={fechaTermino} jornada={jornada} descripcion={descripcion} turnoGeneral={turnoGeneral} setDescripcion={setDescripcion} setJornada={setJornada} setFechaInicio={setFechaInicio} setFechaTermino={setFechaTermino} setTurnoGeneral={setTurnoGeneral} check={check} setCheck={setCheck} turnos={turnos} setOpen6={setOpen3} setOpenBack={setOpen3} openBack={open3} cargos={cargos} open={open} setOpen={setOpen} viceprecidencia={viceprecidencia} empresas={empresas}  gerencia={gerencia} siArea={siArea} setViceprecidencia={setViceprecidencia}/>
                
                <Edit2 formData={formData} data={data} setFormData={setFormData} checkedState={checkedState} setCheckedState={setCheckedState} setSelectedTurnos={setSelectedTurnos} selectedTurnos={selectedTurnos} fechaInicio={fechaInicio} fechaTermino={fechaTermino} jornada={jornada} descripcion={descripcion} turnoGeneral={turnoGeneral} setDescripcion={setDescripcion} setJornada={setJornada} setFechaInicio={setFechaInicio} setFechaTermino={setFechaTermino} setTurnoGeneral={setTurnoGeneral} check={check} setCheck={setCheck} turnos={turnos} setOpen6={setOpen3} setOpenBack={setOpen3} openBack={open3} cargos={cargos} open={openEdit} setOpen={setOpenEdit} viceprecidencia={viceprecidencia} empresas={empresas}  gerencia={gerencia} siArea={siArea} setViceprecidencia={setViceprecidencia} seleItemSolicitud={seleItem} setSeleItemSolicitud={setSeleItem}/>
              </Grid>
            </Grid>
          </div>

          {/*<Table trabajadores={trabajadores} selectedTurnos={selectedTurnos} setSelectedTurnos={setSelectedTurnos}  setCheckedState={setCheckedState} setDescripcion={setDescripcion} setJornada={setJornada} setFechaInicio={setFechaInicio} setFechaTermino={setFechaTermino} setTurnoGeneral={setTurnoGeneral} setCheck={setCheck}/> */}
          { status == 'loading' 
            ? <SkeletonTable/>
            : 
              <Table registros={reservas} setSeleItem2={setSeleItem} seleItem2={seleItem} setOpenEdit={setOpenEdit}/>
              }
        </TableCard>
      </Container>
    </Box>
  )
}

export default Reservas