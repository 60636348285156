import { Backdrop, Box, Container } from "@mui/material";
import Header from "../../components/header/Header";
import TableCard from "../../components/tableCard/TableCard";
import Create from "./componentsCategorias/Create";
import Table from "./componentsCategorias/Table";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import SkeletonTable from "../../components/Skeleton";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import {
  fetchCategorias,
  getAllCategorias,
  getInventariosStatus,
} from "../../redux/features/inventario/inventarioSlice";
import CategoryIcon from "@mui/icons-material/Category";

const Categorias = () => {
  const dispatch = useDispatch();
  const status = useSelector(getInventariosStatus);
  const categorias = useSelector(getAllCategorias);

  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  useEffect(() => {
    if (status === "idle") {
    }
    dispatch(fetchCategorias());
  }, []);

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header
          title="Gestión de categorías"
          subtitle="En este módulo, podrás administrar de manera eficiente todos los aspectos relacionados a las categorías de los articulos registrados en el inventario."
        >
          {<CategoryIcon style={{ fontSize: "2.2rem" }} />}
        </Header>

        <TableCard>
          <Create setOpen2={setOpen2} open2={open2} />
          {status == "loading" ? (
            <SkeletonTable />
          ) : (
            <Table registros={categorias} setOpen3={setOpen3} />
          )}
        </TableCard>
      </Container>
    </Box>
  );
};

export default Categorias;
