import styles from './components.module.css'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { Link } from 'react-router-dom';
import { Button, Grid, Typography } from "@mui/material"
import { useAuth } from '../../../context/AppContext';

const Hero = () => {
    const { info,  } = useAuth();
    const roles = info?.roles.join(' - ');
    console.log('info del usuario', info);
  return (
    <Grid container={true} className={styles.hero}>
        <Grid item xs={12} sm={12} className={styles.heroGrid} style={{ paddingTop:'35px', paddingLeft:'15px', marginLeft: '12px' }}>
          <img src={process.env.PUBLIC_URL + '/logo_white.png' } alt="loginImg" style={{ filter: 'invert(100%)', width:'130px' }} />
        </Grid>
        <Grid item xs={12} sm={12} p={2} className={styles.heroGrid} style={{ paddingTop:'0', marginLeft: '12px' }}>
            <div style={{ display:'flex', flexDirection:'column' }}>
              <span style={{ fontSize:'22px', fontWeight:'600', color: 'black' }}>Bienvenido {info?.nombre}!</span>
              <Typography variant='span' style={{ fontSize:'20px', color: 'black' }}>Comienza a administrar y gestionar los siguientes módulos en la plataforma.</Typography>
            </div>
            <div style={{ display:'flex', flexDirection:'column' }}>
              <span style={{ fontSize:'18px', color: 'black' }}>Rol: {roles ? roles : ''}</span>
              <span style={{ fontSize:'18px', color: 'black' }}>Rut: {info?.rut}</span>
              {info?.contrato && <span style={{ fontSize:'18px', color: 'black' }}>Contrato: {info?.contrato}</span>}
              <span style={{ fontSize:'18px', color: 'black' }}>Telefóno: {info?.celular}</span>
            </div>
            
        </Grid>
    </Grid>
  )
}

export default Hero