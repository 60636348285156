import { Backdrop, Box, Container } from "@mui/material";
import Header from "../../components/header/Header";
import MapIcon from "@mui/icons-material/Map";
import TableCard from "../../components/tableCard/TableCard";
import Create from "./componentsNotificaciones/Create";
import Table from "./componentsNotificaciones/Table";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import SkeletonTable from "../../components/Skeleton";
import { useState } from "react";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import {
  fetchCategorias,
  getAllCategorias,
  getInventariosStatus,
} from "../../redux/features/inventario/inventarioSlice";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  fetchSalidasBodega,
  fetchTraspasosBodegas,
  getAllSalidas,
  getAllTraspasos,
  getTraspasosStatus,
} from "../../redux/features/bodegas/bodegaSlice";

const Categorias = () => {
  const dispatch = useDispatch();
  // const categorias = useSelector(getAllCategorias);
  //   const sectores = useSelector(getAllSectores);
  const salidas = useSelector(getAllSalidas);
  const traspasos = useSelector(getAllTraspasos);
  const traspasosStatus = useSelector(getTraspasosStatus);
  const status = useSelector(getInventariosStatus);
  const [count, setCount] = useState(0);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [registros, setRegistros] = useState([]);

  useEffect(() => {
    if (traspasosStatus === "idle") {
    }
    dispatch(fetchTraspasosBodegas());
    dispatch(fetchSalidasBodega());
  }, []);

  console.log("traspasos->", traspasos);
  console.log("salidas->", salidas);

  useEffect(() => {
    if (traspasos && salidas && traspasos.length > 0 && salidas.length > 0) {
      const traspasosConTipo = traspasos.map((traspaso) => ({
        ...traspaso,
        tipo: "traspaso",
      }));

      const salidasConTipo = salidas.map((salida) => ({
        ...salida,
        tipo: "salida",
      }));

      setRegistros([...traspasosConTipo, ...salidasConTipo]);
    }
  }, [traspasos, salidas]);

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header
          title="Notificaciones"
          subtitle="En este módulo, podrás administrar de manera eficiente las solicitudes de traspaso de inventario."
        >
          {<NotificationsIcon style={{ fontSize: "2.2rem" }} />}
        </Header>

        <TableCard>
          {/*<Create setOpen2={setOpen2} open2={open2}/> */}
          {traspasosStatus == "loading" ? (
            <SkeletonTable />
          ) : (
            <Table registros={registros ?? []} setOpen3={setOpen3} />
          )}
        </TableCard>
      </Container>
    </Box>
  );
};

export default Categorias;
