import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiCard:  {
      styleOverrides: {
        root: {
          boxShadow: 'none !important',
          borderRadius: '0px 0px 5px 5px !important',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: '0px !important',
          '@media (min-width: 1200px)': {
            maxWidth: 'none',
          },
          '@media (min-width: 600px)': {
            maxWidth: 'none',
          }
        },
      },
    },
  },
});

export default theme;