import styles from "./header.module.css"
import { Button, Card, CardContent, Typography } from '@mui/material';

const Header = ({ title, subtitle, children }) => {
  return (
    <Card className={styles.cardBg}>
        <CardContent className={styles.cardContent}>
          <span style={{ marginTop:'13px', marginLeft:'10px' }} className={styles.iconHeader}>
            {children}
          </span>
          <div style={{ display:'flex', flexDirection:'column', marginLeft:'10px' }}>
            <Typography className={styles.header} style={{ fontWeight: '600', fontSize: '43px'  }}>{title}</Typography>
            { subtitle && <span className={styles.subtitle}>{subtitle}</span> }
          </div>
        </CardContent>
    </Card>
  )
}

export default Header