import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import "./App.css";
import Login from "./pages/login/Login";
import SetPass from "./pages/login/SetPass";
import { Routes, Route, Navigate } from "react-router-dom";
import Unauthorized from "./pages/Unauthorized/Unauthorized";
import Layout from "./components/layouts/LayoutAdmin";
import { useAuth } from "./context/AppContext";
import ProtectedRoute from "./components/RequireAuth";
// import ProtectedRoute from './components/RequireAuthDos';
import Usuarios from "./pages/Mantenedores/usuarios/Usuarios";

import Campamentos from "./pages/Mantenedores/campamentos/Campamentos";
import Wings from "./pages/Mantenedores/wing/Wings";
import Sectores from "./pages/Mantenedores/sectores/Sectores";
import Espacios from "./pages/Mantenedores/espacios/Espacios";
import MotivosSolicitud from "./pages/Mantenedores/motivos/MotivosSolicitud";
import Habitaciones from "./pages/Mantenedores/habitaciones/Habitacion";
import Responsables from "./pages/Mantenedores/responsables/Responsables";
// import Reservas from './pages/AdministradorContrato/reservas/Reservas';
import Reservas from "./pages/AdministradorContrato/reservas/ReservasNew";
import LayoutAdminContrato from "./components/layouts/LayoutAdminContrato";
import Dashboard from "./pages/admAplicacion/Dashboard";
import Inventario from "./pages/admInventario/Inventario";
import Articulo from "./pages/admInventario/Articulo";
import Solicitudes from "./pages/recepcionista/reservas/Solicitudes";
import EstadoReserva from "./pages/recepcionista/reservas/EstadoReserva";
import EstadoReservaRecepcionista from "./pages/recepcionista/reservas/EstadoReservaRecepcionista";
import Edificios from "./pages/Mantenedores/edificios/Edificios";
import Standar from "./pages/Mantenedores/standar/Standar";
import DashboardRecepcionista from "./pages/recepcionista/dashboard/Dashboard";
import Historial from "./pages/recepcionista/reservas/Historial";
import CriticidadRequemientos from "./pages/admAplicacion/criticidad/CriticidadRequemientos";
import Otros from "./pages/Mantenedores/otros/Otros";
import Aprobacion from "./pages/AdministradorContrato/reservas/Aprobacion";
// import Oficina from './pages/AdministradorContrato/oficinas/Oficina';
import DashboardHuesped from "./pages/huesped/DashboardHuesped";
import ReclamosSugerencias from "./pages/huesped/ReclamosSugerencias";
import ReclamosSugerenciasAll from "./pages/reclamos_sugerencias_all/ReclamosSugerenciasAll";
import DetallesReservas from "./pages/huesped/DetallesReservas";
import Huespedes from "./pages/Mantenedores/huespedes/Huespedes";
import Ingreso from "./pages/AdministradorContrato/ingreso/Ingreso";
import Gestion from "./pages/AdministradorContrato/gestion/Gestion";
// import Rotativos from './pages/AdministradorContrato/rotativos/Rotativos';
import Rotativos from "./pages/AdministradorContrato/rotativos/RotativosNew";
import Revision from "./pages/AdministradorContrato/revision/Revision";
import Aseo from "./pages/Mantenedores/aseo/Aseo";
import Horario from "./pages/aseo/horario/Horario";
import Tareas from "./pages/aseo/tareas/Tareas";
import Personal from "./pages/aseo/personal/Personal";
import Planificacion from "./pages/aseo/planificacion/Planificacion";
import PlanificacionEspecifica from "./pages/aseo/planificacionEspecifica/PlanificacionEspecifica";
import FeedBack from "./pages/campamentero/feedback/FeedBack";
import Grupos from "./pages/aseo/grupos/Grupos";
import Asignadas from "./pages/aseo/asignadas/Asignadas";
import Listado from "./pages/aseo/listado/Listado";
import Categorias from "./pages/admInventario/Categorias";
import Notificaciones from "./pages/admInventario/Notificaciones";
import NotificacionesSupervisor from "./pages/admInventario/NotificacionesSupervisor";
import Merma from "./pages/admInventario/Merma";
import Ingresos from "./pages/admInventario/Ingresos";
import Salidas from "./pages/admInventario/Salidas";
import Bodega from "./pages/admInventario/Bodega";
import Comparacion from "./pages/admInventario/Comparacion";
import EspaciosBodega from "./pages/admInventario/EspaciosBodega";
import Almacenamiento from "./pages/Adm.Guardarropia/almacenamiento/Almacenamiento";
import AsignacionEspacios from "./pages/Adm.Guardarropia/asignacion-espacios/AsignacionEspacios";
import Oficina from "./pages/admOficina/oficinas/Oficina";
import GestionOficina from "./pages/admOficina/gestion/Gestion";
import Traslado from "./pages/admInventario/Traslado";
import ReservasRecepcionista from "./pages/recepcionista/reservas/Reservas";
import { useEffect } from "react";
import CargaInicial from "./pages/admAplicacion/cargaInicial/CargaInicial";
import ResetPass from "./pages/login/ResetPass";
// import ReservaP_interno from './pages/adm.p_interno/rotativos/ReservaP_interno'
// import ReservaP_interno from './pages/adm.p_interno/rotativos/ReservaP_internoTrab'
import ReservaP_interno from "./pages/adm.p_interno/rotativos/ReservaP_internoReservas";
import Trasabilidad from "./pages/Mantenedores/trasabilidad/Trasabilidad";
import GetToken from "./pages/login/getToken";
import Monitoreo from "./pages/recepcionista/monitoreo/Monitoreo";
import { loadTheme } from './components/themeSwitcher';

function App() {
  const { info } = useAuth();
  const rol = info?.roles;

  useEffect(() => {
    loadTheme();
  }, []);

  return (
    <>
    <style jsx="true" global="true">{`
      :root {
        --bg-color: #d1d3d4;
        --primary-color: black;
        --secondary-color: rgb(113, 128, 150);

        --inst-green-dark-head-Dash: #9ca91c;
        --inst-li-Home-Card: #0086ae;
        --inst-li-Head-Module: #0086ae;
        --inst-li-blue-head-dash-other: #0086ae;
        --inst-icon-background: #f19d38;
        --inst-text-item-color: #fff;
        --inst-icon-slider: #ffffff;
        --inst-icon-background-slider: #f19d38;
        --inst-icon-color: #ffffff;
        --inst-title-color: #ffffff;
        --iconHeader-color: #fff;
        --title-color: #fff;
        --subtitle-color: #fff;
        --toolbar-color: #58595b;
        --inst-li-Head-Module-login: #fcd300;
      }

      [data-theme="dark"] {
        --bg-color: #ffffff;
        --primary-color: #f4f4f6;
        --secondary-color: #8999b0;
        
        --inst-green-dark-head-Dash: #fcd300;
        --inst-li-Home-Card: #ffffff;
        --inst-li-Head-Module: #fcd300;
        --inst-li-blue-head-dash-other: #fcd300;
        --inst-icon-background: #1d1d1b;
        --inst-text-item-color: black;
        --inst-icon-slider: #7a7a7a;
        --inst-icon-background-slider: #212227;
        --inst-icon-color: #dc5a00;
        --inst-title-color: #dc5a00;
        --iconHeader-color: #877000;
        --title-color: #201c1c;
        --subtitle-color: #201c1c;
        --toolbar-color: #dc5a00;
        --inst-li-Head-Module-login: #fcd300;
      }
    `}</style>
    <ThemeProvider theme={theme}>
      <Routes>
        {(rol?.includes("Administrador de Aplicaciones (AAP)") ||
          rol?.includes("Personal de Aseo") ||
          rol?.includes("Administrador de Contrato P_EXTERNO (ACE)") ||
          rol?.includes("Administrador de Contrato P_INTERNO (ACC)") ||
          rol?.includes("Huésped") ||
          rol?.includes("Administrador de Inventario (AIN)") ||
          rol?.includes("Recepcionista") ||
          rol?.includes("Administrador de Guardarropía (AGU)") ||
          rol?.includes("Responsable ingreso de reservas P_EXTERNO (RIR)") ||
          rol?.includes("Administrador de Aseo (AAS)") ||
          rol?.includes("Administrador de Campamento") ||
          rol?.includes("Administrador de Oficina") ||
          rol?.includes("Administrador de RRHH (ARH)") ||
          rol?.includes("Encargado de Aprobaciones Bodega")) && (
          <Route element={<Layout />}>
            <Route
              element={
                <ProtectedRoute
                  allowedRoles={[
                    "Administrador de Aplicaciones (AAP)",
                    "Administrador de Contrato P_EXTERNO (ACE)",
                    "Administrador de Contrato P_INTERNO (ACC)",
                    "Huésped",
                    "Administrador de Inventario (AIN)",
                    "Recepcionista",
                    "Administrador de Guardarropía (AGU)",
                    "Administrador de Aseo (AAS)",
                    "Administrador de Oficina",
                    "Personal de Aseo",
                    "Responsable ingreso de reservas P_EXTERNO (RIR)",
                    "Administrador de RRHH (ARH)",
                    "Encargado de Aprobaciones Bodega",
                    "Administrador de Campamento",
                  ]}
                />
              }
            >
              <Route path="/inicio" element={<Dashboard />} />

              {rol?.includes("Administrador de Aplicaciones (AAP)") && (
                <>
                  <Route path="/campamentos" element={<Campamentos />} />
                  <Route path="/sectores" element={<Sectores />} />
                  <Route path="/wings" element={<Wings />} />
                  <Route path="/pabellones" element={<Edificios />} />
                  <Route path="/otros" element={<Otros />} />
                  <Route path="/habitaciones" element={<Habitaciones />} />
                  <Route path="/estandar" element={<Standar />} />
                  <Route path="/espacios" element={<Espacios />} />
                  <Route
                    path="/criticidad_requerimientos"
                    element={<CriticidadRequemientos />}
                  />
                  <Route path="/oficinas" element={<GestionOficina />} />
                  <Route path="/usuarios" element={<Usuarios />} />
                  <Route path="/responsables" element={<Responsables />} />
                  <Route path="/huespedes" element={<Huespedes />} />
                  <Route path="/carga_inicial" element={<CargaInicial />} />
                  <Route path="/gestion" element={<GestionOficina />} />
                  <Route path="/requerimientos" element={<MotivosSolicitud />} />
                  <Route path="/aseo" element={<Aseo />} />
                  <Route path="/trazabilidad" element={<Trasabilidad />} />
                  {/* <Route path="/oficinas" element={<Oficina />} /> */}
                </>
              )}

              {/*---------- Forecast ADM.CONTRATO P_EXTERNO (ACE), ADM.CONTRATO P_INTERNO (ACC), ADM.RRHH (ARH) ----------*/}
              {rol?.includes("Administrador de Contrato P_EXTERNO (ACE)") && (
                <>
                  <Route path="/ingreso" element={<Ingreso />} />
                  <Route path="/gestion" element={<Gestion />} />
                </>
              )}

              {rol?.includes("Administrador de Oficina") && (
                <>{/* <Route path="/ingreso" element={<Ingreso />} /> */}</>
              )}

              {rol?.includes("Administrador de Contrato P_INTERNO (ACC)") && (
                <>
                  <Route path="/gestion" element={<Gestion />} />
                </>
              )}
              {rol?.includes("Administrador de RRHH (ARH)") && (
                <>
                  <Route path="/gestion" element={<Gestion />} />
                </>
              )}

              {rol?.includes("Administrador de Aseo (AAS)") && (
                <>
                  <Route path="/horarios" element={<Horario />} />
                  <Route path="/planificacion" element={<Planificacion />} />
                  <Route
                    path="/planificacion_especifica"
                    element={<PlanificacionEspecifica />}
                  />
                  <Route path="/grupos" element={<Grupos />} />
                  <Route path="/personal" element={<Personal />} />
                  <Route path="/tareas" element={<Tareas />} />
                  <Route path="/asignadas" element={<Asignadas />} />
                  <Route path="/listado" element={<Listado />} />
                </>
              )}
              {rol?.includes("Personal de Aseo") && (
                <>
                  <Route path="/asignadas" element={<Asignadas />} />
                </>
              )}
              {rol?.includes("Responsable ingreso de reservas P_EXTERNO (RIR)") && (
                <>
                  <Route path="/reservas" element={<Reservas />} />
                </>
              )}
              {rol?.includes("Administrador de Campamento") && (
                <>
                  <Route
                    path="/historial"
                    element={<EstadoReservaRecepcionista />}
                  />
                </>
              )}
              {rol?.includes("Recepcionista") && (
                <>
                  <Route path="/solicitudes" element={<Solicitudes />} />
                  <Route path="/reservas" element={<ReservasRecepcionista />} />
                  <Route
                    path="/historial"
                    element={<EstadoReservaRecepcionista />}
                  />
                  <Route path="/historial2" element={<Historial />} />
                  <Route path="/reservasp_interno" element={<ReservaP_interno />} />
                  <Route path="/turnos_rotativos" element={<Rotativos />} />
                  <Route path="/monitoreo" element={<Monitoreo />} />
                </>
              )}

              {rol?.includes("Administrador de Guardarropía (AGU)") && (
                <>
                  <Route path="/almacenamiento" element={<Almacenamiento />} />
                  <Route
                    path="/asignacion-espacios"
                    element={<AsignacionEspacios />}
                  />
                </>
              )}
              {rol?.includes("Huésped") && (
                <>
                  <Route path="/info-reservas" element={<DetallesReservas />} />
                  <Route
                    path="/reclamos-sugerencias"
                    element={<ReclamosSugerencias />}
                  />
                </>
              )}
              {rol?.includes("Encargado de Aprobaciones Bodega") && (
                <>
                  <Route
                    path="/notificaciones"
                    element={<NotificacionesSupervisor />}
                  />
                </>
              )}
              {rol?.includes("Administrador de Inventario (AIN)") && (
                <>
                  <Route path="/categorias" element={<Categorias />} />
                  <Route path="/inventario" element={<Inventario />} />
                  <Route path="/articulos" element={<Articulo />} />
                  <Route path="/notificaciones" element={<Notificaciones />} />
                  <Route path="/comparacion" element={<Comparacion />} />
                  <Route path="/merma" element={<Merma />} />
                  <Route path="/bodega" element={<Bodega />} />
                  <Route path="/espacios_bodega" element={<EspaciosBodega />} />
                  <Route path="/ingresos" element={<Ingresos />} />
                  <Route path="/salidas" element={<Salidas />} />
                  <Route path="/traspaso" element={<Traslado />} />
                </>
              )}
            </Route>
            <Route path="*" element={<Unauthorized />} />
          </Route>
        )}
        |
        <Route exact path="/" element={<Login />} />
        <Route exact path="/getToken" element={<GetToken />} />
        <Route
          exact
          path="/reclamos_y_sugerencias"
          element={<ReclamosSugerenciasAll />}
        />
        <Route path="/getpass" element={<ResetPass />} />
        <Route exact path="/register/setpassword" element={<SetPass />} />
        <Route exact path="/unauthorized" element={<Unauthorized />} />
        // <Route path="*" element={<span></span>} />
      </Routes>
    </ThemeProvider>
    </>
  );
}

export default App;

//   <Route path='/reservas' element={<ReservaP_interno />} />
//   <Route path='/ingreso' element={<Ingreso />} />
//   <Route path='/aprobacion' element={<Aprobacion/>} />

// <Route path='/revision' element={<Revision />} />
// <Route path='/correcciones' element={<FeedBack />} />

// <------------.. DETALLES A CORREGIR ..---------->
// --------- CORREGIDAS -------
// - [POST] /api/v1/CriticidadRequerimiento devuelve error "ya existe un registro con ese nombre"
//   [PUT] al aprobar o rechazar /api/v1/Forecast/forecastID deja el turno == null para el p_interno y rrhh
// - BUG 462: /api/v1/Aseo/grupo-habitacion ahora debe aceptar oficinas y espacios comunes
// - [POST] de leer todas las notificaciones no actualiza un campo en las notificaciones para diferencias las leidas
// - [GET] de espacios comunes falta el campamento dentro del wing/pabellon

// - [GET] /api/v1/Solicitud/HabitacionesRotativos traer para cada habitacion los cargos permitidos y tipoCama
// - [GET] /api/v1/Solicitud/GetHabitacionesDisponiblesP_INTERNO traer para cada habitacion los cargos permitidos y tipoCama

// - [GET] /api/v1/Solicitud/ReservasP_INTERNO traer el género ***-----> viene un string vacio <-----***

// --------- ACTIVAS -------
// - [GET] /api/v1/Edificio pedir el campamento

// --------- PENDIENTES -------
// - Error en asignación P_INTERNO
// - Arreglar como se ven los datos en las tablas y ultimos detalles esteticos de todo el sistema
// - Ver lo de recuperar contraseña
