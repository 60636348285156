import { Box, Container } from "@mui/material";
import Header from "../../components/header/Header";
import TableCard from "../../components/tableCard/TableCard";
import SkeletonTable from "../../components/Skeleton";
import Create from "./componentsEspacios/Create";
import Table from "./componentsEspacios/Table";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import Grid4x4Icon from "@mui/icons-material/Grid4x4";
import {
  fetchBodegas,
  fetchEspaciosBodega,
  getAllBodegas,
  getAllEspacios,
  getBodegasStatus,
  getEspaciosStatus,
} from "../../redux/features/bodegas/bodegaSlice";

const EspaciosBodega = () => {
  const dispatch = useDispatch();
  const espacios = useSelector(getAllEspacios);
  const status = useSelector(getEspaciosStatus);
  const bodegas = useSelector(getAllBodegas);
  const bodStatus = useSelector(getBodegasStatus);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  useEffect(() => {
    if (status === "idle") {
    }
    dispatch(fetchEspaciosBodega());
    if (bodStatus === "idle") {
    }
    dispatch(fetchBodegas());
  }, []);

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header
          title="Gestión de espacios en bodega"
          subtitle="En este módulo, podrás asociar espacios a las bodegas registradas en la plataforma."
        >
          {<Grid4x4Icon style={{ fontSize: "2.2rem" }} />}
        </Header>

        <TableCard>
          <Create setOpen2={setOpen2} open2={open2} bodegas={bodegas} />
          {status == "loading" ? (
            <SkeletonTable />
          ) : (
            <Table registros={espacios ? espacios : []} setOpen3={setOpen3} />
          )}
        </TableCard>
      </Container>
    </Box>
  );
};
export default EspaciosBodega;
