import styles from "./components/components.module.css";
import { Container, Box, Grid, Card, CardContent } from "@mui/material";
import { useAppContext } from "../../context/AppContext";
import { Navigate } from "react-router-dom";
import { useEffect } from "react";
import ResetForm from "./components/RecuperarPass";

const ResetPass = () => {
  return (
    <div className="w-full d-flex align-items-center justify-content-center h-full" 
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/fondo-inicio.jpg"})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Box className="h-100 login_box d-flex align-items-center justify-content-center">
        <Container maxWidth="xs" style={{ display: "flex" }}>
          <Card
            style={{
              boxShadow: "0px 10px 20px 2px rgba(0, 0, 0, 0.25)",
              borderRadius: "6px",
              // paddingTop: "20px",
              minHeight: "440px",
            }}
            className={styles.cardBgBlur}
          >
            <CardContent className="text-start" style={{backgroundColor: "var(--inst-li-Head-Module-login)", padding: "0" }}>
              <Grid container alignItems="center">
                <Grid item xs={6} style={{ paddingTop: "0px" }}>
                  <h2
                    style={{
                      padding: "20px",
                      gap: "5px",
                      fontWeight: "600",
                      whiteSpace: "nowrap"
                    }}
                  >
                      RECUPERAR CONTRASEÑA
                  </h2>
                </Grid>
                <Grid item xs={6} style={{ paddingTop: "0px", textAlign: "right" }}>
                  <img
                    src={process.env.PUBLIC_URL + "/psinet.jpg"}
                    alt="loginImg"
                    style={{ width: '60%', padding: "20px", marginTop: "4px", filter: "brightness(0) invert(1)" }}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardContent>
              <ResetForm />
            </CardContent>
          </Card>
        </Container>
      </Box>
    </div>
  );
};

export default ResetPass;
