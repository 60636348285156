import styles from "./sidebar.module.css";
import { styled, useTheme } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import { navDataAdministradorAplicacion } from "../../lib/navData";
import WarningIcon from "@mui/icons-material/Warning";
import Box from "@mui/material/Box";
import DownloadIcon from "@mui/icons-material/Download";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import EventIcon from "@mui/icons-material/Event";
import MapIcon from "@mui/icons-material/Map";
import ExtensionIcon from "@mui/icons-material/Extension";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import AssignmentIcon from "@mui/icons-material/Assignment";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ApartmentIcon from "@mui/icons-material/Apartment";
import SettingsInputSvideoIcon from "@mui/icons-material/SettingsInputSvideo";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import { Outlet } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import { useAuth } from "../../context/AppContext";
import { useDispatch, useSelector } from "react-redux";
import { getActualRole, getUser } from "../../redux/features/login/loginSlice";
import { useEffect, useState } from "react";
import OtherHousesIcon from "@mui/icons-material/OtherHouses";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TextsmsIcon from "@mui/icons-material/Textsms";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TimelineIcon from "@mui/icons-material/Timeline";
import HistoryIcon from "@mui/icons-material/History";
import HubIcon from "@mui/icons-material/Hub";
import StormIcon from "@mui/icons-material/Storm";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";

import NightShelterIcon from "@mui/icons-material/NightShelter";
import ChecklistIcon from "@mui/icons-material/Checklist";

import CategoryIcon from "@mui/icons-material/Category";
import CompareIcon from "@mui/icons-material/Compare";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import Grid4x4Icon from "@mui/icons-material/Grid4x4";
import AlignHorizontalCenterIcon from "@mui/icons-material/AlignHorizontalCenter";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { loadTheme, switchTheme } from '../themeSwitcher';

import LogoutIcon from "@mui/icons-material/Logout";
import {
  addNewBudget,
  fetchBudgets,
  getAllBudgets,
  getBuddgetsStatus,
} from "../../redux/features/budget/budgetSlice";

import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { createAxiosInstance } from "../../api/axios";
import {
  getAllNotifications,
  getNotifications,
} from "../../redux/features/users/userSlice";
import { format } from "date-fns";
import { orange } from "@mui/material/colors";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const drawerWidth = 480;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      width: `calc(100% - ${drawerWidth}px) !important`,// añadido 19-11-2024
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  // padding: theme.spacing(0, 1),
  // // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
  paddingTop: '40px',
  justifyContent: "flex-end",
}));

export default function PersistentDrawerLeft() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { logout } = useAuth();
  const { info, notificaciones, getNotifications } = useAuth();

  const [expanded, setExpanded] = useState(false);
  const [expandedRooms, setExpandedRooms] = useState(false);

  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const [isAdminInventario, setIsAdminInventario] = useState(
    info?.roles.includes("Administrador de Inventario (AIN)")
  );
  const [isAdminContrato, setIsAdminContrato] = useState(
    info?.roles.includes("Administrador de Contrato P_INTERNO (ACC)")
  );
  const [isRecepcionista, setIsRecepcionista] = useState(
    info?.roles.includes("Recepcionista")
  );
  // const [budget, setBudgets] = useState(['uno','dos']);

  const handleOpen = () => {
    setOpenNotificacion(true);
  };
  const handleClose = () => {
    setOpenNotificacion(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleRoomsChange = (panel) => (event, isExpanded) => {
    setExpandedRooms(isExpanded ? panel : false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // console.log('role', info);
  }, [info]);

  const enlaces2 = [
    {
      role: [
        "Administrador de Inventario (AIN)",
        "Administrador de Contrato P_EXTERNO (ACE)",
        "Administrador de Contrato P_INTERNO (ACC)",
        "Recepcionista",
        "Huésped",
        "Administrador de Aplicaciones (AAP)",
        "Administrador de RRHH (ARH)",
        "Administrador de Guardarropía (AGU)",
        "Administrador de Aseo (AAS)",
        "Administrador de Oficina",
        "Personal de Aseo",
        "Responsable ingreso de reservas P_EXTERNO (RIR)",
        "Encargado de Aprobaciones Bodega",
        "Administrador de Campamento",
      ],
      to: "/inicio",
      text: "Inicio",
      icon: (
        <DashboardIcon
          style={{
            background: 'var(--inst-icon-background-slider)',
            color: 'var(--inst-icon-slider)',
            padding: "8px",
            borderRadius: "50%",
            height: "40px",
            width: "40px",
          }}
        />
      ),
    },
  ];
  const enlaces = [
    {
      role: "Administrador de Inventario (AIN)",
      to: "/categorias",
      text: "Categorías",
      icon: (
        <CategoryIcon
          style={{
            background: 'var(--inst-icon-background-slider)',
            color: 'var(--inst-icon-slider)',
            padding: "8px",
            borderRadius: "50%",
            height: "40px",
            width: "40px",
          }}
        />
      ),
    },
    {
      role: "Administrador de Inventario (AIN)",
      to: "/inventario",
      text: "Inventario",
      icon: (
        <HolidayVillageIcon
          style={{
            background: 'var(--inst-icon-background-slider)',
            color: 'var(--inst-icon-slider)',
            padding: "8px",
            borderRadius: "50%",
            height: "40px",
            width: "40px",
          }}
        />
      ),
    },
    {
      role: "Administrador de Inventario (AIN)",
      to: "/comparacion",
      text: "Toma de inventario",
      icon: (
        <CompareIcon
          style={{
            background: 'var(--inst-icon-background-slider)',
            color: 'var(--inst-icon-slider)',
            padding: "8px",
            borderRadius: "50%",
            height: "40px",
            width: "40px",
          }}
        />
      ),
    },
    {
      role: "Administrador de Inventario (AIN)",
      to: "/merma",
      text: "Merma",
      icon: (
        <RestoreFromTrashIcon
          style={{
            background: 'var(--inst-icon-background-slider)',
            color: 'var(--inst-icon-slider)',
            padding: "8px",
            borderRadius: "50%",
            height: "40px",
            width: "40px",
          }}
        />
      ),
    },
    {
      role: "Administrador de Inventario (AIN)",
      to: "/bodega",
      text: "Bodega",
      icon: (
        <WarehouseIcon
          style={{
            background: 'var(--inst-icon-background-slider)',
            color: 'var(--inst-icon-slider)',
            padding: "8px",
            borderRadius: "50%",
            height: "40px",
            width: "40px",
          }}
        />
      ),
    },
    {
      role: "Administrador de Inventario (AIN)",
      to: "/espacios_bodega",
      text: "Espacios en bodega",
      icon: (
        <Grid4x4Icon
          style={{
            background: 'var(--inst-icon-background-slider)',
            color: 'var(--inst-icon-slider)',
            padding: "8px",
            borderRadius: "50%",
            height: "40px",
            width: "40px",
          }}
        />
      ),
    },
    {
      role: "Administrador de Inventario (AIN)",
      to: "/ingresos",
      text: "Ingresos",
      icon: (
        <AlignHorizontalCenterIcon
          style={{
            background: 'var(--inst-icon-background-slider)',
            color: 'var(--inst-icon-slider)',
            padding: "8px",
            borderRadius: "50%",
            height: "40px",
            width: "40px",
          }}
        />
      ),
    },
    {
      role: "Administrador de Inventario (AIN)",
      to: "/salidas",
      text: "Salidas",
      icon: (
        <ExitToAppIcon
          style={{
            background: 'var(--inst-icon-background-slider)',
            color: 'var(--inst-icon-slider)',
            padding: "8px",
            borderRadius: "50%",
            height: "40px",
            width: "40px",
          }}
        />
      ),
    },
    {
      role: "Administrador de Inventario (AIN)",
      to: "/traspaso",
      text: "Traspaso entre bodegas",
      icon: (
        <ExitToAppIcon
          style={{
            background: 'var(--inst-icon-background-slider)',
            color: 'var(--inst-icon-slider)',
            padding: "8px",
            borderRadius: "50%",
            height: "40px",
            width: "40px",
          }}
        />
      ),
    },
    {
      role: "Administrador de Inventario (AIN)",
      to: "/notificaciones",
      text: "Notificaciones",
      icon: (
        <NotificationsIcon
          style={{
            background: 'var(--inst-icon-background-slider)',
            color: 'var(--inst-icon-slider)',
            padding: "8px",
            borderRadius: "50%",
            height: "40px",
            width: "40px",
          }}
        />
      ),
    },
    {
      role: "Encargado de Aprobaciones Bodega",
      to: "/notificaciones",
      text: "Notificaciones",
      icon: (
        <NotificationsIcon
          style={{
            background: 'var(--inst-icon-background-slider)',
            color: 'var(--inst-icon-slider)',
            padding: "8px",
            borderRadius: "50%",
            height: "40px",
            width: "40px",
          }}
        />
      ),
    },
    {
      role: "Administrador de Aseo (AAS)",
      to: "/horarios",
      text: "Horarios",
      icon: (
        <WatchLaterIcon
          style={{
            background: 'var(--inst-icon-background-slider)',
            color: 'var(--inst-icon-slider)',
            padding: "8px",
            borderRadius: "50%",
            height: "40px",
            width: "40px",
          }}
        />
      ),
    },
    {
      role: "Administrador de Aseo (AAS)",
      to: "/grupos",
      text: "Grupos de habitaciones",
      icon: (
        <NightShelterIcon
          style={{
            background: 'var(--inst-icon-background-slider)',
            color: 'var(--inst-icon-slider)',
            padding: "8px",
            borderRadius: "50%",
            height: "40px",
            width: "40px",
          }}
        />
      ),
    },
    {
      role: "Administrador de Aseo (AAS)",
      to: "/planificacion",
      text: "Planificación Diaria",
      icon: (
        <MenuBookIcon
          style={{
            background: 'var(--inst-icon-background-slider)',
            color: 'var(--inst-icon-slider)',
            padding: "8px",
            borderRadius: "50%",
            height: "40px",
            width: "40px",
          }}
        />
      ),
    },
    {
      role: "Administrador de Aseo (AAS)",
      to: "/planificacion_especifica",
      text: "Planificación Especifica",
      icon: (
        <BookmarkAddIcon
          style={{
            background: 'var(--inst-icon-background-slider)',
            color: 'var(--inst-icon-slider)',
            padding: "8px",
            borderRadius: "50%",
            height: "40px",
            width: "40px",
          }}
        />
      ),
    },
    // {
    //   role: 'Administrador de Aseo (AAS)',
    //   to: '/asignadas',
    //   text: 'Habitaciones asignadas',
    //   icon: <ChecklistIcon style={{ background:'#f19d38', padding:'8px', borderRadius:'50%', height:'35px', width:'35px' }}/>
    // },
    {
      role: "Administrador de Aseo (AAS)",
      to: "/tareas",
      text: "Tareas de limpieza",
      icon: (
        <CalendarMonthIcon
          style={{
            background: 'var(--inst-icon-background-slider)',
            color: 'var(--inst-icon-slider)',
            padding: "8px",
            borderRadius: "50%",
            height: "40px",
            width: "40px",
          }}
        />
      ),
    },
    {
      role: "Administrador de Aseo (AAS)",
      to: "/personal",
      text: "Personal de aseo",
      icon: (
        <PersonIcon
          style={{
            background: 'var(--inst-icon-background-slider)',
            color: 'var(--inst-icon-slider)',
            padding: "8px",
            borderRadius: "50%",
            height: "40px",
            width: "40px",
          }}
        />
      ),
    },
    // {
    //   role: 'Administrador de Inventario (AIN)',
    //   to: '/articulos',
    //   text: 'Articulos',
    //   icon: <HolidayVillageIcon />
    // },
    // {
    //   role: ['Administrador de Contrato P_EXTERNO (ACE)'],
    //   to: '/reservas',
    //   text: 'Reservas',
    //   icon: <EventIcon style={{ background:'#f19d38', padding:'8px', borderRadius:'50%', height:'35px', width:'35px' }}/>
    // },
    // {
    //   role: ['Administrador de Contrato P_EXTERNO (ACE)'],
    //   to: '/estado',
    //   text: 'Estado Reservas',
    //   icon: <EventIcon style={{ background:'#f19d38', padding:'8px', borderRadius:'50%', height:'35px', width:'35px' }}/>
    // },
    {
      role: "Administrador de Guardarropía (AGU)",
      to: "/almacenamiento",
      text: "Almacenamiento",
      icon: (
        <WatchLaterIcon
          style={{
            background: 'var(--inst-icon-background-slider)',
            color: 'var(--inst-icon-slider)',
            padding: "8px",
            borderRadius: "50%",
            height: "40px",
            width: "40px",
          }}
        />
      ),
    },
    {
      role: "Administrador de Guardarropía (AGU)",
      to: "/asignacion-espacios",
      text: "Asignacion de espacios",
      icon: (
        <NightShelterIcon
          style={{
            background: 'var(--inst-icon-background-slider)',
            color: 'var(--inst-icon-slider)',
            padding: "8px",
            borderRadius: "50%",
            height: "40px",
            width: "40px",
          }}
        />
      ),
    },
    // {
    //   role: ['Administrador de Contrato P_INTERNO (ACC)'],
    //   to: '/aprobacion',
    //   text: 'Aprobación solicitudes',
    //   icon: <FactCheckIcon />
    // },
    // {
    //   role: ['Administrador de Contrato P_INTERNO (ACC)'],
    //   to: '/turnos_rotativos',
    //   text: 'Turnos rotativos',
    //   icon: <FactCheckIcon  style={{ background:'#f19d38', padding:'8px', borderRadius:'50%', height:'35px', width:'35px'}}/>
    // },
    {
      role: "Huésped",
      to: "/info-reservas",
      text: "Reservas",
      icon: (
        <EventIcon
          style={{
            background: 'var(--inst-icon-background-slider)',
            color: 'var(--inst-icon-slider)',
            padding: "8px",
            borderRadius: "50%",
            height: "40px",
            width: "40px",
          }}
        />
      ),
    },
    {
      role: "Huésped",
      to: "/reclamos-sugerencias",
      text: "Reclamos y Sugerencias",
      icon: (
        <TextsmsIcon
          style={{
            background: 'var(--inst-icon-background-slider)',
            color: 'var(--inst-icon-slider)',
            padding: "8px",
            borderRadius: "50%",
            height: "40px",
            width: "40px",
          }}
        />
      ),
    },
    // {
    //   role: 'Recepcionista',
    //   to: '/gestion',
    //   descripcion: 'Gestión de oficinas',
    //   text: 'Gestión de oficinas',
    //   icon: <BookmarkAddIcon style={{ background:'#f19d38', padding:'8px', borderRadius:'50%', height:'35px', width:'35px'}}/>
    // },
    {
      role: "Personal de Aseo",
      to: "/asignadas",
      descripcion: "Gestión de habitaciones asignadas",
      text: "Gestión de habitaciones asignadas",
      icon: (
        <ChecklistIcon
          style={{
            background: 'var(--inst-icon-background-slider)',
            color: 'var(--inst-icon-slider)',
            padding: "8px",
            borderRadius: "50%",
            height: "40px",
            width: "40px",
          }}
        />
      ),
    },
    {
      role: "Responsable ingreso de reservas P_EXTERNO (RIR)",
      to: "/reservas",
      descripcion: "Reservas",
      text: "Reservas",
      icon: (
        <ChecklistIcon
          style={{
            background: 'var(--inst-icon-background-slider)',
            color: 'var(--inst-icon-slider)',
            padding: "8px",
            borderRadius: "50%",
            height: "40px",
            width: "40px",
          }}
        />
      ),
    },
    // {
    //   role: 'Administrador de Guardarropía (AGU)',
    //   to: '/horarios',
    //   text: 'Horarios de aseo',
    //   icon: <WatchLaterIcon />
    // },
    // {
    //   role: 'Administrador de Guardarropía (AGU)',
    //   to: '/planificacion',
    //   text: 'Planificaciòn de horarios',
    //   icon: <TimelineIcon />
    // },
    // {
    //   role: 'Administrador de Guardarropía (AGU)',
    //   to: '/planificacion_especifica',
    //   text: 'Planificacion especìfica',
    //   icon: <TimelineIcon />
    // },
    // {
    //   role: 'Administrador de Guardarropía (AGU)',
    //   to: '/grupos',
    //   text: 'Grupos de habitaciones',
    //   icon: <TimelineIcon />
    // },
    // {
    //   role: 'Administrador de Guardarropía (AGU)',
    //   to: '/asignadas',
    //   text: 'Espacios asignados',
    //   icon: <TimelineIcon />
    // },
  ];

  const enlacesRecepcionista = [
    // {
    //   role: 'Recepcionista',
    //   to: '/solicitudes',
    //   text: 'Solicitudes',
    //   descripcion: 'Asignar habitaciones',
    //   icon: <TimelineIcon />
    // },
    // {
    //   role: 'Recepcionista',
    //   to: '/reservas',
    //   text: 'Reservas P_INTERNO',
    //   descripcion: 'Revisar habitaciones',
    //   icon: <HolidayVillageIcon />
    // },
    {
      role: "Recepcionista",
      to: "/historial",
      descripcion: "Historial de reservas P_EXTERNO",
      text: "Historial de solicitudes",
      icon: (
        <HistoryIcon
          style={{
            background: 'var(--inst-icon-background-slider)',
            color: 'var(--inst-icon-slider)',
            padding: "8px",
            borderRadius: "50%",
            height: "40px",
            width: "40px",
          }}
        />
      ),
    },
    {
      role: "Recepcionista",
      to: "/reservasp_interno",
      descripcion: "Gestión de reservas P_INTERNO",
      text: "Asignación P_INTERNO",
      icon: (
        <ChecklistIcon
          style={{
            background: 'var(--inst-icon-background-slider)',
            color: 'var(--inst-icon-slider)',
            padding: "8px",
            borderRadius: "50%",
            height: "40px",
            width: "40px",
          }}
        />
      ),
    },
  ];
  const enlacesForecast = [
    {
      role: [
        // 'Administrador de Contrato P_INTERNO (ACC)',
        "Administrador de Contrato P_EXTERNO (ACE)",
        //  'Administrador de RRHH (ARH)'
      ],
      to: "/ingreso",
      text: "Ingreso",
      icon: <StormIcon />,
    },
    // {
    //   role: [
    //     'Administrador de Contrato P_INTERNO (ACC)',
    //     ],
    //   to: '/aprobacion',
    //   text: 'Aprobación',
    //   icon: <FactCheckIcon />
    // },
    {
      role: ["Administrador de RRHH (ARH)"],
      to: "/gestion",
      text: "Gestión",
      icon: <StormIcon />,
    },
    {
      role: [
        "Administrador de Contrato P_INTERNO (ACC)",
        //  'Administrador de RRHH (ARH)',
        "Administrador de Contrato P_EXTERNO (ACE)",
      ],
      to: "/gestion",
      text: "Gestión de Forecast",
      icon: <FlipCameraAndroidIcon />,
    },
    // {
    //   role: [ 'Administrador de Contrato P_EXTERNO (ACE)'],
    //   to: '/correcciones',
    //   text: 'Correcciones y retroalimentación',
    //   icon: <FlipCameraAndroidIcon />
    // },
    // {
    //   role: [ 'Administrador de Contrato P_EXTERNO (ACE)'],
    //   to: '/gestion',
    //   text: 'Gestión de forecast',
    //   icon: <FlipCameraAndroidIcon />
    // },
  ];

  useEffect(() => {
    if (status == "idle") {
      dispatch(fetchBudgets());
    }
  }, []);

  const [loader, setLoader] = useState(false);
  const [existe, setExiste] = useState(false);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const dispatch = useDispatch();
  const budgets = useSelector(getAllBudgets);
  const status = useSelector(getBuddgetsStatus);

  const [formData, setFormData] = useState({
    budget: "",
    confirmacion: "",
  });

  const onChangeFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("budget:", file);
      setFormData((prev) => ({ ...prev, budget: file }));
    }
    const exists =
      budgets &&
      Array.isArray(budgets) &&
      budgets.findIndex((item) => item.name === file.name) !== -1;
    setExiste(exists);
  };

  const handleFileBudget = async () => {
    try {
      setLoader(true);
      setAddRequestStatus("loading");
      const resp = await dispatch(addNewBudget(formData));
      if (resp.data.status === "200") {
        setFormData({ budget: "", confirmacion: "" });
        setOpen3(false);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoader(false);
      setAddRequestStatus("idle");
      setOpenConfirm(false);
    }
  };

  const handleDownload = () => {
    // Crea un blob con el contenido del archivo PDF.
    const pdfContent = new Uint8Array([
      /* Aquí puedes poner el contenido binario de tu PDF */
    ]);
    const blob = new Blob([pdfContent], { type: "application/pdf" });

    // Crea un URL para el blob.
    const url = `${process.env.PUBLIC_URL}/Manual de Campamento Psinet.pdf`;

    // Crea un enlace y lo hace clic automáticamente para iniciar la descarga.
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Manual de Campamento Psinet.pdf"); // Nombre del archivo PDF
    document.body.appendChild(link);
    link.click();

    // Limpia el objeto URL para liberar memoria.
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  };

  const [openNotification, setOpenNotificacion] = useState(false);
  const [check, setCheck] = useState(false);
  // const [notificationsList, setNotificationsList] = useState([]);
  const [notificationStatus, setNotificationStatus] = useState({});
  const navigate = useNavigate();
  const axiosInstance = createAxiosInstance();

  // const notificationsList = useSelector(getAllNotifications);
  // const getNotifications = async () => {
  //   try {
  //     const resp = await axiosInstance.get('/api/v1/Auth/GetNotificaciones');
  //     setNotificationsList(resp.data);
  //   } catch (error) {
  //     console.log('error al obtener las notificaciones: --> ', error);
  //   }
  // }

  // useEffect(() => {
  //   dispatch(getNotifications());
  // }, [info])

  const markAllAsRead = async () => {
    try {
      await axiosInstance.post("/api/v1/Auth/ReadNotificacion");
      getNotifications();
    } catch (error) {
      console.log("error al obtener las notificaciones: --> ", error);
    }
  };

  const handleNotificationClick = (notification) => {
    // marcar como leida una notificación en especifica
    // navigate(notification.link);
    // setOpenNotificacion(false);
  };

  const [savedTheme, setTheme] = useState('light');

  useEffect(() => {
    loadTheme();
    const savedTheme = localStorage.getItem('theme');
    setTheme(savedTheme);
  }, []);

  const handleSwitchTheme = () => {
    switchTheme();
    const savedTheme = localStorage.getItem('theme');
    setTheme(savedTheme);
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        overflowX: "hidden",
        marginTop: "0",
      }}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        className={styles.appBar}
        style={{ boxShadow: "none", borderBottom: "1px solid black" }}
      >
        <Toolbar
          style={{
            height: "fit-content",
            minHeight: "60px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: 'var(--toolbar-color)',
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            <Button
              style={{
                color: "#fff",
                display: "flex",
                alignItems: "center",
                gap: "2px",
                fontSize: "20px"
              }}
              onClick={logout}
            >
              <LogoutIcon style={{ fontSize: "20px" }} />
              Cerrar Sesión
            </Button>
          </Typography>

          {/* --------------- START NOTIFICACIONES ---------------*/}

          <div style={{ position: "relative" }}>
            <IconButton color="inherit" onClick={() => { handleSwitchTheme();}}>   
              {savedTheme === 'light' ? <DarkModeIcon /> : <LightModeIcon />}
            </IconButton>

            <IconButton
              color="inherit"
              onClick={() => {
                setCheck(true);
                setOpenNotificacion((prev) => !prev);
              }}
            >
              <Badge
                badgeContent={
                  notificaciones.filter(
                    (notification) => notification.leida === false
                  ).length
                }
                color={"error"}
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
            {openNotification && (
              <div
                style={{
                  position: "absolute",
                  zIndex: "10000000",
                  top: "48px",
                  right: "0",
                  backgroundColor: "#fff",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "5px",
                  color: "#333",
                  width: "200px",
                }}
              >
                <Button
                  onClick={markAllAsRead}
                  style={{
                    width: "100%",
                    padding: "10px",
                    backgroundColor: "#0086ae",
                    color: "#fff",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  <span style={{ fontSize: "12px", fontWeight: "500" }}>
                    Marcar todas como leídas
                  </span>
                </Button>
                <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                  {notificaciones.map((notification) => (
                    <div
                      key={notification.id}
                      onClick={() => handleNotificationClick(notification)}
                      style={{
                        padding: "10px",
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: notification.leida
                          ? "#e0e0e0"
                          : "white",
                        color: "#2d3037",
                        cursor: "pointer",
                      }}
                    >
                      <span style={{ fontSize: "14px", fontWeight: "500" }}>
                        {notification.entidad}
                      </span>
                      <span style={{ fontSize: "12px", fontWeight: "300" }}>
                        {notification.mensaje}
                      </span>
                      <span style={{ fontSize: "12px", fontWeight: "300" }}>
                        {format(notification.fecha, "dd-MM-yyyy")}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* --------------- END NOTIFICACIONES ---------------*/}
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "var(--background-sidebar)",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          {info?.nombre && (
            <div
            style={{
              width: "100%",
              paddingLeft: '25px',
              paddingBottom: '10px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              overflow: 'hidden',
              boxSizing: 'border-box',
              maxWidth: '100%'
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/logo_black.png"}
              alt="loginImg"
              style={{ width: "150px", paddingBottom: '40px', flexGrow: 1 }}
            />
            <span style={{ color: "white", fontWeight: "400", flexGrow: 1, fontSize: "1.4em" }}>
              Bienvenido:
            </span>
            <span style={{ width: "100%", paddingBottom: '40px', color: "white", fontWeight: "400", flexGrow: 1, fontSize: "1.4em" }}>
              {info?.nombre}
            </span>
          </div>
          
          )}
          <IconButton onClick={handleDrawerClose} style={{ padding: 0 }}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon style={{ color: "#fff" }} />
            ) : (
              <ChevronRightIcon style={{ color: "#fff" }} />
            )}
          </IconButton>
        </DrawerHeader>
        <List>
          <Divider />
          {enlaces2.map((enlace, index) => {
            if (info?.roles.some((role) => enlace.role.includes(role))) {
              return (
                <NavLink
                  key={index}
                  className={styles.sideitem}
                  to={enlace.to}
                  style={{ color: "white", textDecoration: "none" }}
                >
                  <ListItem disablePadding>
                    <ListItemButton>
                      {enlace.icon}
                      <span className={styles.linkText}>{enlace.text}</span>
                    </ListItemButton>
                  </ListItem>
                </NavLink>
              );
            }
            return null; // Si el rol no está incluido en info.roles, no renderiza el enlace
          })}

          {info?.roles.includes("Administrador de Aplicaciones (AAP)") && (
            <>
              <Accordion
                key={1}
                expanded={expanded === `panel${1}`}
                onChange={handleChange(`panel${1}`)}
                style={{ border: "none", boxShadow: "none" }}
              >
                <AccordionSummary
                  className={styles.accordionSummary}
                  expandIcon={
                    <KeyboardArrowDownIcon style={{ color: "white" }} />
                  }
                  style={{ backgroundColor: "#212227", color: "white" }}
                >
                  <ListItem disablePadding>
                    <ListItemButton
                      style={{ color: "white", paddingLeft: "17px" }}
                    >
                      <DashboardIcon
                        style={{
                          background: 'var(--inst-icon-background-slider)',
                          color: 'var(--inst-icon-slider)',
                          padding: "8px",
                          borderRadius: "50%",
                          height: "40px",
                          width: "40px",
                        }}
                      />

                      <Typography
                        className={styles.linkText}
                        style={{ color: "white", marginLeft: "0", fontSize: "21px" }}
                      >
                        Espacios Físicos
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: "#212227" }}>
                  <div>
                    <NavLink
                      to="/campamentos"
                      style={{ color: "white", textDecoration: "none" }}
                    >
                      <ListItem disablePadding>
                        <ListItemButton>
                          <HolidayVillageIcon
                            style={{
                              background: 'var(--inst-icon-background-slider)',
                              color: 'var(--inst-icon-slider)',
                              padding: "8px",
                              borderRadius: "50%",
                              height: "40px",
                              width: "40px",
                            }}
                          />
                          <Typography className={styles.linkText}>
                            Campamentos
                          </Typography>
                        </ListItemButton>
                      </ListItem>
                    </NavLink>

                    <NavLink
                      to="/sectores"
                      style={{ color: "white", textDecoration: "none" }}
                    >
                      <ListItem disablePadding>
                        <ListItemButton>
                          <MapIcon
                            style={{
                              background: 'var(--inst-icon-background-slider)',
                              color: 'var(--inst-icon-slider)',
                              padding: "8px",
                              borderRadius: "50%",
                              height: "40px",
                              width: "40px",
                            }}
                          />
                          <Typography className={styles.linkText}>
                            Sectores
                          </Typography>
                        </ListItemButton>
                      </ListItem>
                    </NavLink>

                    <NavLink
                      to="/oficinas"
                      style={{ color: "white", textDecoration: "none" }}
                    >
                      <ListItem disablePadding>
                        <ListItemButton>
                          <BookmarkAddIcon
                            style={{
                              background: 'var(--inst-icon-background-slider)',
                              color: 'var(--inst-icon-slider)',
                              padding: "8px",
                              borderRadius: "50%",
                              height: "40px",
                              width: "40px",
                            }}
                          />
                          <Typography className={styles.linkText}>
                            Oficinas
                          </Typography>
                        </ListItemButton>
                      </ListItem>
                    </NavLink>

                    <Accordion
                      key={2}
                      expanded={expandedRooms === `subpanel${0}-${2}`}
                      onChange={handleRoomsChange(`subpanel${0}-${2}`)}
                      style={{ border: "none", boxShadow: "none" }}
                    >
                      <AccordionSummary
                        className={styles.accordionSummary}
                        expandIcon={
                          <KeyboardArrowDownIcon style={{ color: "white" }} />
                        }
                        style={{ backgroundColor: "#212227", color: "white" }}
                      >
                        <ListItem disablePadding>
                          <ListItemButton style={{ padding: "0" }}>
                            <ApartmentIcon
                              style={{
                                background: 'var(--inst-icon-background-slider)',
                                color: 'var(--inst-icon-slider)',
                                padding: "8px",
                                borderRadius: "50%",
                                height: "40px",
                                width: "40px",
                              }}
                            />
                            <Typography
                              className={styles.linkText}
                              style={{ marginLeft: "0" }}
                            >
                              Recintos
                            </Typography>
                          </ListItemButton>
                        </ListItem>
                      </AccordionSummary>
                      <AccordionDetails style={{ backgroundColor: "#212227" }}>
                        <div>
                          <NavLink
                            to="/wings"
                            style={{ color: "white", textDecoration: "none" }}
                          >
                            <ListItem disablePadding>
                              <ListItemButton>
                                <LabelImportantIcon
                                  style={{
                                    background: 'var(--inst-icon-background-slider)',
                                    color: 'var(--inst-icon-slider)',
                                    padding: "8px",
                                    borderRadius: "50%",
                                    height: "40px",
                                    width: "40px",
                                  }}
                                />
                                <Typography className={styles.linkText}>
                                  Wings
                                </Typography>
                              </ListItemButton>
                            </ListItem>
                          </NavLink>

                          <NavLink
                            to="/pabellones"
                            style={{ color: "white", textDecoration: "none" }}
                          >
                            <ListItem disablePadding>
                              <ListItemButton>
                                <ApartmentIcon
                                  style={{
                                    background: 'var(--inst-icon-background-slider)',
                                    color: 'var(--inst-icon-slider)',
                                    padding: "8px",
                                    borderRadius: "50%",
                                    height: "40px",
                                    width: "40px",
                                  }}
                                />
                                <Typography className={styles.linkText}>
                                  Pabellones
                                </Typography>
                              </ListItemButton>
                            </ListItem>
                          </NavLink>

                          <NavLink
                            to="/otros"
                            style={{ color: "white", textDecoration: "none" }}
                          >
                            <ListItem disablePadding>
                              <ListItemButton>
                                <OtherHousesIcon
                                  style={{
                                    background: 'var(--inst-icon-background-slider)',
                                    color: 'var(--inst-icon-slider)',
                                    padding: "8px",
                                    borderRadius: "50%",
                                    height: "40px",
                                    width: "40px",
                                  }}
                                />
                                <Typography className={styles.linkText}>
                                  Otros
                                </Typography>
                              </ListItemButton>
                            </ListItem>
                          </NavLink>
                        </div>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      key={3}
                      expanded={expandedRooms === `subpanel${0}-${3}`}
                      onChange={handleRoomsChange(`subpanel${0}-${3}`)}
                      style={{ border: "none", boxShadow: "none" }}
                    >
                      <AccordionSummary
                        className={styles.accordionSummary}
                        expandIcon={
                          <KeyboardArrowDownIcon style={{ color: "white" }} />
                        }
                        style={{ backgroundColor: "#212227", color: "white" }}
                      >
                        <ListItem disablePadding>
                          <ListItemButton style={{ padding: "0" }}>
                            <MeetingRoomIcon
                              style={{
                                background: 'var(--inst-icon-background-slider)',
                                color: 'var(--inst-icon-slider)',
                                padding: "8px",
                                borderRadius: "50%",
                                height: "40px",
                                width: "40px",
                              }}
                            />
                            <Typography
                              className={styles.linkText}
                              style={{ marginLeft: "0" }}
                            >
                              Áreas
                            </Typography>
                          </ListItemButton>
                        </ListItem>
                      </AccordionSummary>
                      <AccordionDetails style={{ backgroundColor: "#212227" }}>
                        <div>
                          <NavLink
                            to="/habitaciones"
                            style={{ color: "white", textDecoration: "none" }}
                          >
                            <ListItem disablePadding>
                              <ListItemButton>
                                <MeetingRoomIcon
                                  style={{
                                    background: 'var(--inst-icon-background-slider)',
                                    color: 'var(--inst-icon-slider)',
                                    padding: "8px",
                                    borderRadius: "50%",
                                    height: "40px",
                                    width: "40px",
                                  }}
                                />
                                <Typography className={styles.linkText}>
                                  Habitaciones
                                </Typography>
                              </ListItemButton>
                            </ListItem>
                          </NavLink>

                          <NavLink
                            to="/estandar"
                            style={{ color: "white", textDecoration: "none" }}
                          >
                            <ListItem disablePadding>
                              <ListItemButton>
                                <SettingsInputSvideoIcon
                                  style={{
                                    background: 'var(--inst-icon-background-slider)',
                                    color: 'var(--inst-icon-slider)',
                                    padding: "8px",
                                    borderRadius: "50%",
                                    height: "40px",
                                    width: "40px",
                                  }}
                                />
                                <Typography className={styles.linkText}>
                                  Estandares
                                </Typography>
                              </ListItemButton>
                            </ListItem>
                          </NavLink>

                          <NavLink
                            to="/espacios"
                            style={{ color: "white", textDecoration: "none" }}
                          >
                            <ListItem disablePadding>
                              <ListItemButton>
                                <LabelImportantIcon
                                  style={{
                                    background: 'var(--inst-icon-background-slider)',
                                    color: 'var(--inst-icon-slider)',
                                    padding: "8px",
                                    borderRadius: "50%",
                                    height: "40px",
                                    width: "40px",
                                  }}
                                />
                                <Typography className={styles.linkText}>
                                  Espacios Comunes
                                </Typography>
                              </ListItemButton>
                            </ListItem>
                          </NavLink>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </AccordionDetails>
              </Accordion>

              <NavLink
                className={styles.sideitem}
                to="/criticidad_requerimientos"
                style={{ color: "white", textDecoration: "none" }}
              >
                <ListItem disablePadding>
                  <ListItemButton style={{ paddingLeft: "12px" }}>
                    <TroubleshootIcon
                      style={{
                        background: 'var(--inst-icon-background-slider)',
                        color: 'var(--inst-icon-slider)',
                        padding: "8px",
                        borderRadius: "50%",
                        height: "40px",
                        width: "40px",
                      }}
                    />
                    <span className={styles.linkText}>
                      Criticidad de requerimientos
                    </span>
                  </ListItemButton>
                </ListItem>
              </NavLink>

              <NavLink
                className={styles.sideitem}
                to="/carga_inicial"
                style={{ color: "white", textDecoration: "none" }}
              >
                <ListItem disablePadding>
                  <ListItemButton style={{ paddingLeft: "12px" }}>
                    <BookmarkAddIcon
                      style={{
                        background: 'var(--inst-icon-background-slider)',
                        color: 'var(--inst-icon-slider)',
                        padding: "8px",
                        borderRadius: "50%",
                        height: "40px",
                        width: "40px",
                      }}
                    />
                    <span className={styles.linkText}>Carga Inicial</span>
                  </ListItemButton>
                </ListItem>
              </NavLink>

              <NavLink
                className={styles.sideitem}
                to="/trazabilidad"
                style={{ color: "white", textDecoration: "none" }}
              >
                <ListItem disablePadding>
                  <ListItemButton style={{ paddingLeft: "12px" }}>
                    <BookmarkAddIcon
                      style={{
                        background: 'var(--inst-icon-background-slider)',
                        color: 'var(--inst-icon-slider)',
                        padding: "8px",
                        borderRadius: "50%",
                        height: "40px",
                        width: "40px",
                      }}
                    />
                    <span className={styles.linkText}>Trazabilidad</span>
                  </ListItemButton>
                </ListItem>
              </NavLink>

              <Accordion
                key={3}
                expanded={expanded === `panel${3}`}
                onChange={handleChange(`panel${3}`)}
                style={{ border: "none", boxShadow: "none" }}
              >
                <AccordionSummary
                  className={styles.accordionSummary}
                  expandIcon={
                    <KeyboardArrowDownIcon style={{ color: "white" }} />
                  }
                  style={{ backgroundColor: "#212227", color: "white" }}
                >
                  <ListItem disablePadding>
                    <ListItemButton style={{ color: "white" }}>
                      <GroupIcon
                        style={{
                          background: 'var(--inst-icon-background-slider)',
                          color: 'var(--inst-icon-slider)',
                          padding: "8px",
                          borderRadius: "50%",
                          height: "40px",
                          width: "40px",
                        }}
                      />
                      <Typography
                        className={styles.linkText}
                        style={{ color: "white", marginLeft: "0", fontSize: "21px" }}
                      >
                        Usuarios
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: "#212227" }}>
                  <div>
                    <NavLink
                      to="/usuarios"
                      style={{ color: "white", textDecoration: "none" }}
                    >
                      <ListItem disablePadding>
                        <ListItemButton>
                          <GroupIcon
                            style={{
                              background: 'var(--inst-icon-background-slider)',
                              color: 'var(--inst-icon-slider)',
                              padding: "8px",
                              borderRadius: "50%",
                              height: "40px",
                              width: "40px",
                            }}
                          />
                          <Typography className={styles.linkText}>
                            Administradores
                          </Typography>
                        </ListItemButton>
                      </ListItem>
                    </NavLink>

                    <NavLink
                      to="/responsables"
                      style={{ color: "white", textDecoration: "none" }}
                    >
                      <ListItem disablePadding>
                        <ListItemButton>
                          <PersonIcon
                            style={{
                              background: 'var(--inst-icon-background-slider)',
                              color: 'var(--inst-icon-slider)',
                              padding: "8px",
                              borderRadius: "50%",
                              height: "40px",
                              width: "40px",
                            }}
                          />
                          <Typography className={styles.linkText}>
                            Responsables
                          </Typography>
                        </ListItemButton>
                      </ListItem>
                    </NavLink>

                    <NavLink
                      to="/huespedes"
                      style={{ color: "white", textDecoration: "none" }}
                    >
                      <ListItem disablePadding>
                        <ListItemButton>
                          <DashboardIcon
                            style={{
                              background: 'var(--inst-icon-background-slider)',
                              color: 'var(--inst-icon-slider)',
                              padding: "8px",
                              borderRadius: "50%",
                              height: "40px",
                              width: "40px",
                            }}
                          />
                          <Typography className={styles.linkText}>
                            Huéspedes
                          </Typography>
                        </ListItemButton>
                      </ListItem>
                    </NavLink>
                  </div>
                </AccordionDetails>
              </Accordion>
            </>
          )}

          {info?.roles.some((role) =>
            [
              "Administrador de Contrato P_INTERNO (ACC)",
              "Administrador de Contrato P_EXTERNO (ACE)",
              "Administrador de RRHH (ARH)",
            ].includes(role)
          ) && (
            <>
              <Accordion
                key={3}
                expanded={expanded === `panel${4}`}
                onChange={handleChange(`panel${4}`)}
                style={{ border: "none", boxShadow: "none" }}
              >
                <AccordionSummary
                  className={styles.accordionSummary}
                  expandIcon={
                    <KeyboardArrowDownIcon style={{ color: "white" }} />
                  }
                  style={{ backgroundColor: "#212227", color: "white" }}
                >
                  <ListItem disablePadding>
                    <ListItemButton style={{ color: "white" }}>
                      <HubIcon
                        style={{
                          background: 'var(--inst-icon-background-slider)',
                          color: 'var(--inst-icon-slider)',
                          padding: "8px",
                          borderRadius: "50%",
                          height: "40px",
                          width: "40px",
                        }}
                      />
                      <Typography
                        className={styles.linkText}
                        style={{ color: "white", marginLeft: "0", fontSize: "21px" }}
                      >
                        Forecast
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: "#212227" }}>
                  <div>
                    {enlacesForecast.map((enlace, index) => {
                      if (
                        info?.roles.some((role) => enlace.role.includes(role))
                      ) {
                        return (
                          <NavLink to={enlace.to} className={styles.sideitem}>
                            <ListItem disablePadding>
                              <ListItemButton>
                                {enlace.icon}
                                <Typography className={styles.linkText}>
                                  {enlace.text}
                                </Typography>
                              </ListItemButton>
                            </ListItem>
                          </NavLink>
                        );
                      }
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>
            </>
          )}

          {info?.roles.some((role) => ["Recepcionista"].includes(role)) && (
            <Accordion
              key={3}
              expanded={expanded === `panel${4}`}
              onChange={handleChange(`panel${4}`)}
              style={{ border: "none", boxShadow: "none" }}
            >
              <AccordionSummary
                className={styles.accordionSummary}
                expandIcon={
                  <KeyboardArrowDownIcon style={{ color: "white" }} />
                }
                style={{ backgroundColor: "#212227", color: "white" }}
              >
                <ListItem disablePadding>
                  <ListItemButton style={{ color: "white" }}>
                    <HubIcon
                      style={{
                        background: 'var(--inst-icon-background-slider)',
                        color: 'var(--inst-icon-slider)',
                        padding: "8px",
                        borderRadius: "50%",
                        height: "40px",
                        width: "40px",
                      }}
                    />
                    <Typography
                      className={styles.linkText}
                      style={{ color: "white", marginLeft: "0", fontSize: "21px" }}
                    >
                      Reservas
                    </Typography>
                  </ListItemButton>
                </ListItem>
              </AccordionSummary>
              <AccordionDetails style={{ backgroundColor: "#212227" }}>
                <div>
                  {enlacesRecepcionista.map((enlace, index) => {
                    if (
                      info?.roles.some((role) => enlace.role.includes(role))
                    ) {
                      return (
                        <NavLink
                          to={enlace.to}
                          style={{ color: "white", textDecoration: "none" }}
                        >
                          <ListItem disablePadding>
                            <ListItemButton
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {enlace.icon}
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography className={styles.linkText}>
                                  {enlace.text}
                                </Typography>
                                <span
                                  className={styles.linkText}
                                  style={{ fontSize: "10px" }}
                                >
                                  {enlace.descripcion}
                                </span>
                              </div>
                            </ListItemButton>
                          </ListItem>
                        </NavLink>
                      );
                    }
                  })}
                </div>
              </AccordionDetails>
            </Accordion>
          )}

          {enlaces.map((enlace, index) => {
            if (info?.roles.some((role) => enlace.role.includes(role))) {
              return (
                <NavLink key={index} className={styles.sideitem} to={enlace.to}>
                  <ListItem disablePadding>
                    <ListItemButton>
                      {enlace.icon}
                      <span className={styles.linkText}>{enlace.text}</span>
                    </ListItemButton>
                  </ListItem>
                </NavLink>
              );
            }
            return null; // Si el rol no está incluido en info.roles, no renderiza el enlace
          })}
        </List>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {info?.roles.includes("Administrador de Contrato P_INTERNO (ACC)") ||
            info?.roles.includes("Administrador de RRHH (ARH)") ||
            (info?.roles.includes("Administrador de Contrato P_EXTERNO (ACE)") && (
              <Button
                style={{ color: "white", border: "none" }}
                variant="outlined"
                onClick={() => setOpen2(true)}
              >
                Descargar Budget
              </Button>
            ))}

          {(info?.roles.includes("Administrador de Contrato P_INTERNO (ACC)") ||
            info?.roles.includes("Administrador de RRHH (ARH)")) && (
            <Button
              style={{ color: "white", border: "none" }}
              variant="outlined"
              onClick={() => setOpen3(true)}
            >
              Subir Budget
            </Button>
          )}
          {info?.roles.includes("Huésped") && (
            <Button
              className={styles.bgColorBlue}
              variant="outlined"
              onClick={handleDownload}
            >
              Descargar manual de campamento
            </Button>
          )}
        </div>

        <Dialog open={open2} onClose={() => setOpen2(false)}>
          <DialogTitle
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DownloadIcon /> Budgets disponibles para descarga
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Grid container={true} spacing={2}>
                <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
                  <Typography className={styles.typ}>
                    archivo_falso.xls
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
                  <Typography className={styles.typ}>
                    archivo_falso.xls
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
                  <Typography className={styles.typ}>
                    archivo_falso.xls
                  </Typography>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className={styles.bgColorOutlined}
              variant="outlined"
              onClick={() => setOpen2(false)}
            >
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={open3}
          onClose={() => setOpen3(false)}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <CloudUploadIcon />
            Subir Budget
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {/* AQUI VA LA LISTA DE BUDGETS, AGREGAR CONDICIONAL Y MENSAJE EN CASO DE QUE NO HAYAN BUDGETS */}
              <Grid container={true} spacing={2}>
                {budgets &&
                  Array.isArray(budgets) &&
                  budgets.length > 0 &&
                  budgets.map((item) => (
                    <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
                      <Typography className={styles.typ}>
                        {item?.name}
                      </Typography>
                    </Grid>
                  ))}
              </Grid>
              <Grid container={true} spacing={2} mt={2}>
                <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="budget"
                    type="file"
                    onChange={(e, value) => onChangeFile(e, value)}
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className={styles.bgColor}
              variant="contained"
              disabled={!formData.budget}
              onClick={() => setOpenConfirm(true)}
            >
              Confirmar
            </Button>
            <Button
              className={styles.bgColorOutlined}
              variant="outlined"
              style={{ border: "none" }}
              onClick={() => setOpen3(false)}
            >
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openConfirm}
          onClose={() => setOpenConfirm(false)}
          aria-labelledby="draggable-dialog-title"
        >
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <DialogTitle
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {existe && (
              <h7 style={{ display: "flex", alignItems: "center" }}>
                <WarningIcon /> Ya existe un budget con este nombre
              </h7>
            )}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Grid container={true} spacing={2}>
                <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                  <span style={{ display: "block", fontSize: "16px" }}>
                    ¿Deseas continuar?{" "}
                    {existe &&
                      "el budget actual se reemplazará con el budget que estás a punto de subir."}
                  </span>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className={styles.bgColor}
              variant="contained"
              onClick={handleFileBudget}
            >
              {addRequestStatus === "loading" ? (
                <CircularProgress color="inherit" />
              ) : (
                "Confirmar"
              )}
            </Button>
            <Button
              className={styles.bgColorOutlined}
              style={{ border: "none" }}
              variant="outlined"
              onClick={() => {
                // setOpen3(false)
                setOpenConfirm(false);
              }}
            >
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </Drawer>
      <Main open={open} style={{ width: "100%", padding: "0" }}>
        <DrawerHeader />
        <Outlet />
      </Main>
    </Box>
  );
}