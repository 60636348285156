import { Backdrop, Box, Container } from "@mui/material";
import Header from "../../../components/header/Header";
import MapIcon from "@mui/icons-material/Map";
import TableCard from "../../../components/tableCard/TableCard";
import Create from "./components/Create";
import Table from "./components/Table";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  fetchSectores,
  getAllSectores,
  getSectoresStatus,
} from "../../../redux/features/sectores/sectorSlice";
import SkeletonTable from "../../../components/Skeleton";
import {
  fetchCampamento,
  fetchCampamentos,
  getAllCampamentos,
  getCampamentosStatus,
} from "../../../redux/features/campamentos/campamentoSlice";
import { useState } from "react";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import {
  fetchEspacios,
  getAllEspacios,
  getEspacioStatus,
} from "../../../redux/features/guardarropia/guardarropiaSlice";

const Almacenamiento = () => {
  const dispatch = useDispatch();
  const campamentos = useSelector(getAllCampamentos);
  const campamentosStatus = useSelector(getCampamentosStatus);
  const espacios = useSelector(getAllEspacios);
  const status = useSelector(getEspacioStatus);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  useEffect(() => {
    if (status == "idle") {
      dispatch(fetchEspacios());
    }
    if (campamentosStatus == "idle") {
      dispatch(fetchCampamentos());
    }
  }, []);

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header
          title="Gestión de espacios de almacenamiento"
          subtitle="En este módulo, podrás administrar de manera eficiente los espacios de almacenamiento de las instalaciones."
        >
          {<MapIcon style={{ fontSize: "2.2rem" }} />}
        </Header>

        <TableCard>
          <Create
            campamentos={campamentos}
            setOpen2={setOpen2}
            open2={open2}
            espacios={espacios}
          />
          {status == "loading" ? (
            <SkeletonTable />
          ) : (
            <Table registros={espacios} setOpen3={setOpen3} />
          )}
        </TableCard>
      </Container>
    </Box>
  );
};

export default Almacenamiento;
