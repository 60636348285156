import { Box, Container } from "@mui/material";
import Header from "../../components/header/Header";
import TableCard from "../../components/tableCard/TableCard";
import SkeletonTable from "../../components/Skeleton";
import Create from "./componentsMerma/Create";
import Table from "./componentsMerma/Table";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import {
  fetchInventarios,
  getAllInventarios,
  getInventariosStatus,
} from "../../redux/features/inventario/inventarioSlice";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import {
  fetchCampamentos,
  getAllCampamentos,
  getCampamentosStatus,
} from "../../redux/features/campamentos/campamentoSlice";
import {
  fetchMermas,
  getAllMermas,
  getMermasStatus,
} from "../../redux/features/merma/mermaSlice";
import {
  fetchBodegas,
  fetchEspaciosBodega,
  getAllBodegas,
  getAllEspacios,
  getBodegasStatus,
  getEspaciosStatus,
} from "../../redux/features/bodegas/bodegaSlice";

const Comparacion = () => {
  const dispatch = useDispatch();
  const mermas = useSelector(getAllMermas);
  const status = useSelector(getMermasStatus);
  const campamentos = useSelector(getAllCampamentos);
  const camStatus = useSelector(getCampamentosStatus);
  const inventarios = useSelector(getAllInventarios);
  const invStatus = useSelector(getInventariosStatus);
  const bodegas = useSelector(getAllBodegas);
  const bodStatus = useSelector(getBodegasStatus);
  const espacios = useSelector(getAllEspacios);
  const espStatus = useSelector(getEspaciosStatus);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchMermas());
    }
    if (invStatus === "idle") {
      dispatch(fetchInventarios());
    }
    if (bodStatus === "idle") {
    }
    dispatch(fetchBodegas());
    if (camStatus === "idle") {
      dispatch(fetchCampamentos());
    }
    if (espStatus === "idle") {
      dispatch(fetchEspaciosBodega());
    }
  }, []);

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header
          title="Gestión de Merma"
          subtitle="En este módulo, podrás gestionar la merma del inventario."
        >
          {<RestoreFromTrashIcon style={{ fontSize: "2.2rem" }} />}
        </Header>

        <TableCard>
          <Create
            setOpen2={setOpen2}
            open2={open2}
            campamentos={campamentos}
            bodegas={bodegas}
            espacios={espacios}
            inventarios={inventarios}
          />
          {status == "loading" ? (
            <SkeletonTable />
          ) : (
            <Table registros={mermas} setOpen3={setOpen3} />
          )}
        </TableCard>
      </Container>
    </Box>
  );
};
export default Comparacion;
