import { Backdrop, Box, Container, Tab, CircularProgress } from "@mui/material";
import Header from "../../../components/header/Header";
import MapIcon from "@mui/icons-material/Map";
import TableCard from "../../../components/tableCard/TableCard";
import Create from "./components/Create";
import Table from "./components/Table";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  fetchCampamentos,
  getAllCampamentos,
  getCampamentosStatus,
} from "../../../redux/features/campamentos/campamentoSlice";
import {
  fetchEspacios,
  fetchIngresosGuardarropia,
  fetchSalidasGuardarropia,
  getAllEspacios,
  getAllIngresosGuardarropia,
  getAllRetirosGuardarropia,
  getSolicitudesStatus,
} from "../../../redux/features/guardarropia/guardarropiaSlice";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SkeletonTable from "../../../components/Skeleton";
import Retiro from "./components/Retiro";
import TableRetiro from "./components/TableRetiro";

const AsignacionEspacios = () => {
  const dispatch = useDispatch();
  const campamentos = useSelector(getAllCampamentos);
  const camStatus = useSelector(getCampamentosStatus);
  const espacios = useSelector(getAllEspacios);
  const status = useSelector(getSolicitudesStatus);
  const ingresos = useSelector(getAllIngresosGuardarropia);
  const retiros = useSelector(getAllRetirosGuardarropia);

  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [value, setValue] = useState("1");
  const [data, setData] = useState([]);
  const [retirosData, setRetirosData] = useState([]);

  useEffect(() => {
    dispatch(fetchEspacios());
    if (status === "idle") {
    }
    dispatch(fetchSalidasGuardarropia());
    dispatch(fetchIngresosGuardarropia());
    if (camStatus === "idle") {
      dispatch(fetchCampamentos());
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header
          title="Modulo de Asignación de espacios"
          subtitle="En este módulo, podras asignar un espacio disponible a un trabajador, ingresar sus implementos de seguridad e ingresar un registro del retiro total o parcial de la implementación de seguridad."
        >
          {<MapIcon style={{ fontSize: "2.2rem" }} />}
        </Header>

        <TableCard>
          <Create
            ingresos={ingresos}
            campamentos={campamentos}
            registros={espacios}
            setOpen2={setOpen2}
            open2={open2}
            espacios={espacios}
            setValue={setValue}
          />
          <Retiro
            campamentos={campamentos}
            registros={espacios}
            setOpen2={setOpen2}
            open2={open2}
            espacios={espacios}
            ingresos={ingresos}
            setValue={setValue}
          />

          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  sx={{
                    "& .MuiTab-root": { color: "gray" },
                    "& .MuiTab-root.Mui-selected": { color: "#2d3037" },
                    "& .MuiTabs-indicator": { bgcolor: "#2d3037" },
                  }}
                >
                  <Tab label="Ingresos" value="1" />
                  <Tab label="Retiros" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1" style={{ padding: "0" }}>
                {status === "loading" ? (
                  <SkeletonTable />
                ) : (
                  <Table registros={ingresos} setOpen3={setOpen3} />
                )}
              </TabPanel>
              <TabPanel value="2" style={{ padding: "0" }}>
                {status === "loading" ? (
                  <SkeletonTable />
                ) : (
                  <TableRetiro registros={retiros} setOpen3={setOpen3} />
                )}
              </TabPanel>
            </TabContext>
          </Box>
        </TableCard>
      </Container>
    </Box>
  );
};

export default AsignacionEspacios;
