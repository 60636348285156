import styles from "./components/components.module.css";
import { Box, Container } from "@mui/material";
import Header from "../../../components/header/Header";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import TableCard from "../../../components/tableCard/TableCard";
import Create from "./components/Create";
import Table from "./components/Table";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  fetchCampamentos,
  getAllCampamentos,
  getCampamentosStatus,
} from "../../../redux/features/campamentos/campamentoSlice";
import SkeletonTable from "../../../components/Skeleton";
import { Backdrop, CircularProgress } from "@mui/material";

const Campamentos = () => {
  const dispatch = useDispatch();
  const campamentos = useSelector(getAllCampamentos);
  const status = useSelector(getCampamentosStatus);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [scroll, setScroll] = useState("paper");

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchCampamentos());
    }
  }, [campamentos]);

  return (
    <Box width={1} mt={0} mb={2}>
      <Container>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open3}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header
          title="Gestión de campamentos"
          subtitle="En este módulo, podrás administrar de manera eficiente todos los aspectos relacionados con los campamentos en la plataforma."
        >
          {
            <HolidayVillageIcon
              style={{ fontSize: "1.5rem" }}
            />
          }
        </Header>

        <TableCard>
          <Create setOpen2={setOpen2} open2={open2} />
          {status == "loading" ? (
            <SkeletonTable />
          ) : (
            <Table
              campamentos={campamentos ? campamentos : []}
              setOpen3={setOpen3}
              scroll={scroll}
              setScroll={setScroll}
            />
          )}
        </TableCard>
      </Container>
    </Box>
  );
};
export default Campamentos;
